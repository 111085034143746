/* eslint-disable jsx-a11y/no-redundant-roles */
import { Player } from '@lottiefiles/react-lottie-player';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

export default function Pricing({ data }) {
  if (data && data.length === 0) {
    return null;
  }
  return (
    <>
      <section id="paquetes" className="relative z-10 px-5 pt-20 pb-10 overflow-visible md:pt-32">
        <div className="relative w-full max-w-xl p-10 mx-auto text-center md:p-16 rounded-3xl md:max-w-5xl 2xl:max-w-7xl bg-brand-main-lighter text-brand-main">
          <Player
            autoplay
            loop
            src="./lotties/packages.json"
            className="h-[200px] w-[200px] xl:w-[230px] xl:h-[230px] absolute top-0 -translate-y-1/2 right-1/2 translate-x-1/2 xl:right-0 xl:translate-x-36 xl:-translate-y-36 "
          />
          <div className="">
            <div className="mx-auto ">
              <h
                className="pt-10 mt-5 mb-4 text-2xl text-center text-brand-secondary xl :mt-0 md:pt-0 md:mb-8 md:text-4xl payfair "
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Empieza tu camino hoy
              </h>
              <p
                className="pb-5 mx-auto text-sm text-center text-brand-secondary md:text-base max-w-prose"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                Haz click en el botón reservar sesión para seleccionar tu horario de preferencia y
                agendar nuestra sesión
              </p>
              <div
                className={`${
                  data.length >= 3 ? 'grid grid-cols-1 lg:grid-cols-3' : 'flex'
                }  justify-center max-w-md gap-8 mx-auto mt-10 isolate lg:mx-0 lg:max-w-none `}
              >
                {data.map((item) => (
                  <div
                    key={item.name}
                    className={`p-5 rounded-3xl ring-1  bg-white md:hover:scale-105 transition-all duration-200 ${
                      item.popular ? 'ring-1 ring-brand-brown ' : 'ring-1  ring-brand-main'
                    }`}
                  >
                    <div className="flex items-center justify-between gap-x-4">
                      <h3
                        id="tier-freelancer"
                        className="text-lg font-semibold leading-8 text-left payfair md:text-xl text-brand-brown"
                      >
                        {item.name}
                      </h3>
                    </div>
                    <p className="mt-4 text-sm leading-6 text-left text-brand-main">
                      {item.description}
                    </p>

                    {item.call_to_action1 && item.url1 && (
                      <Link
                        href={item.url1}
                        target="_blank"
                        aria-describedby="tier-freelancer"
                        className="block px-3 py-2 mt-6 text-sm font-semibold leading-6 text-center text-white transition-all duration-200 bg-brand-btn hover:brightness-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-btn "
                      >
                        {item.call_to_action1}
                      </Link>
                    )}
                    {item.call_to_action2 && item.url2 && (
                      <Link
                        href={item.url2}
                        aria-describedby="tier-freelancer"
                        className="block px-3 py-2 mt-6 text-sm font-semibold leading-6 text-center text-white transition-all duration-200 bg-brand-btn hover:brightness-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-btn "
                      >
                        {item.call_to_action2}
                      </Link>
                    )}
                    <ul
                      role="list"
                      className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                    >
                      {item.features
                        && item.features.map((i) => (
                          <li key={i} className="flex text-left gap-x-3">
                            <svg
                              className="flex-none w-5 h-6 text-brand-brown"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {i}
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="paquetes" className="relative z-10 px-5 overflow-hidden">
        <div className="w-full max-w-xl mx-auto text-center rounded-3xl md:max-w-5xl 2xl:max-w-7xl text-brand-main">
          <Link className="text-base md:text-lg text-brand-btn" href="/contacto/#faqs">
            {' '}
            ¿Tienes preguntas? Encuentra respuestas
            {' '}
            <span className="underline">aquí</span>
            {' '}
            o
            envíame un mensaje
            {' '}
          </Link>
        </div>
      </section>
    </>
  );
}

Pricing.propTypes = {
  data: PropTypes.array,
};
